import React from 'react'
import { Formik } from 'formik';


function Bulten() {
  return (
    <>
      <span className='menubaslik borderrad mt-3' style={{ color: "rgba(0, 0, 0, 0.5)" }}> <i className="fa-solid fa-envelope" style={{ color: "rgba(0, 0, 0, 0.5)", marginRight: "6px" }}></i> E-bulletin</span>

      <Formik
        initialValues={{ email: '' }}

        onSubmit={(values) => {
          console.log(values)
          document.getElementById("memail").value="Address  add...";
          setInterval(()=>(document.getElementById("memail").value="Enter your e-mail address..."), 3000);
          clearInterval();
        }}
      >

        {

          ({handleSubmit, handleChange}) => (

            <form onSubmit={handleSubmit} onChange={handleChange}>

              <input className="d-block w-100  mt-3 p-1 form-control" id="memail" name="memail" placeholder="Enter your e-mail address..." type="email" required />
              <button className='btn btn-success btn-md mt-2' type="submit">Add to list</button>
            </form>

          )

        }


      </Formik>

    </>
  )
}

export default Bulten