import Carousel from 'react-bootstrap/Carousel';
import hkf from "../img/hkf.jpg";
import busworld from "../img/busworld.jpg";
import foteg from "../img/foteg.jpg";
import viv from "../img/viv.jpg";
import { Link } from "react-router-dom";


function hkfCarousel() {
  return  (
    <Carousel>



<Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src={hkf}
          alt="BUSWORLD TÜRKİYE"/>
        <Carousel.Caption>
          <h1>Right decision, 
	right move !</h1>
          <h3>Prime quality international trade fairs in Türkiye</h3>
          
          <Link to="/aboutus" type="button" className="button-24">Read more</Link>
        </Carousel.Caption>
      </Carousel.Item>
    

      <Carousel.Item interval={2000}>
        <img
          className="d-block w-100"
          src={busworld}
          alt="BUSWORLD TÜRKİYE"/>
        <Carousel.Caption>
          <h1>BUSWORLD TÜRKİYE</h1>
          <h3>International Trade Fair For Bus Industry and Accessories</h3>
          <a href="https://www.busworldturkey.org/en" type="button" className="button-24">Website</a>
        </Carousel.Caption>
      </Carousel.Item>


      <Carousel.Item interval={2000}>
        <img
          className="d-block w-100"
          src={foteg}
          alt="Third slide"
        />
        <Carousel.Caption>
          <h1>FOTEG ISTANBUL</h1>
          <h3>International Trade Fair For Food Processing Technologies</h3>
          <a href="http://www.fotegistanbul.com/" type="button" className="button-24">Website</a>
        </Carousel.Caption>
      </Carousel.Item>


      <Carousel.Item interval={2000}>
        <img
          className="d-block w-100"
          src={viv}
          alt="VIV TÜRKİYE"
        />
        <Carousel.Caption>
          <h1>VIV SELECT TÜRKİYE</h1>
          <h3>
          International Trade Fair For Poultry Technologies </h3>
          <a href="http://www.vivturkey.com/" type="button" className="button-24">Website</a>
        </Carousel.Caption>
      </Carousel.Item>


     

      
    </Carousel>
  );
}

export default hkfCarousel;