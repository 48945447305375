import React from 'react'
import Container from 'react-bootstrap/Container';
import Logo from '../img/hkflogo.svg';



const Header = () => {
  return (
    <Container><img src={Logo} alt="HKF" />
    
    <div className="float-end mt-2">
    <a href="https://www.facebook.com/HKFTradeFairs">  <i className="fa-brands fa-facebook btn-socialicons"></i></a>
    <a href="https://twitter.com/HKFTradeFairs">   <i className="fa-brands fa-twitter btn-socialicons"></i></a>
    <a href="https://www.youtube.com/user/HKFTradeFairs">   <i className="fa-brands fa-youtube btn-socialicons"></i></a>
    <a href="https://www.instagram.com/hkftradefairs">  <i className="fa-brands fa-square-instagram btn-socialicons"></i></a>

    
    </div>
    </Container>
  )
}

export default Header
