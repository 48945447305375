import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Submenu from "./Submenu"
import Bulten from "./Bulten"


const Hakkimizda = () => {
  return (
    <>
      <div className="subpage">
        <Container>
          <h3>Prime quality international trade fairs in TÜRKİYE</h3>
        </Container>

      </div>
      <Container className="mt-3">

        <Row>
          <Col md={9}>

            <h4>About us</h4>
            <div className='line2 mt-2 mb-3'></div>

            <p> HKF Trade Fairs was founded in 2002 in Istanbul with the objective of organizing prime quality international 
              trade fairs in TÜRKİYE. Since 2004 our company is a full member of UFI, the Global Association of the Exhibition Industry. 
              Bekir Çakıcı, Chariman of our company, has been a member of the Board of Directors of UFI since 2006 and since 2014 he has 
              been the Vice Chairman of the European Chapter of UFI, both positions he held until 2020.</p>

            <p> International Trade Fair for Stockbreeding Technologies and Animal Products ANIMALIA ISTANBUL, International Trade Fair for Food 
              Processing Technologies FOTEG ISTANBUL, 
            International Trade Fair for Poultry and Technologies VIV TÜRKİYE, International Trade Fair for Bus Industry and Accessories 
              BUSWORLD TÜRKİYE, Trade Fair for Road, Sea and Air Taxi Producers and Subindustry TAXIWORLD TÜRKİYE and newly added two brand new trade 
              shows, which are Music, Dance and Stage Equipment, Event Organization and Communication Technologies Fair ISTANBUL MUSIC AND DANCE and 
              Trade Fair for Drink Technologies DRINKTEG ISTANBUL are among the trade fairs organized by HKF Trade Fairs.</p>

            <p> VIV TÜRKİYE, which is biannually organized under the exclusive license of the Netherlands based VNU Exhibitions Europe, is the biggest international poultry show in TÜRKİYE and its neighboring countries. In addition to that, International Trade Fair for Bus Industry and Accessories BUSWORLD TÜRKİYE, which has been biannually organized since 2007 in cooperation with Belgian, based BUSWORLD KORTRIJK are the trade shows organized with the international partners of our company.</p>

            <p> In addition to BUSWORLD TÜRKİYE and VIV TÜRKİYE, FOTEG ISTANBUL and ANIMALIA ISTANBUL also have the distinction of having earned the UFI Approved Event Certificate and are therefore are included in the International World Fairs Calendar of UFI. On 12-15 November 2008, for the first time in the Turkish Exhibition History, the 75th UFI Congress was organized in Istanbul and our company HKF Trade Fairs had the honor of being the Host of the Congress.</p>

            <p>In June 2010 our company had the honor of becoming the first and only EURASCO, The European Federation Agricultural Exhibition and Show Organizers, member accepted from TÜRKİYE. Therefore, our international trade fairs ANIMALIA ISTANBUL, FOTEG ISTANBUL and VIV TÜRKİYE earned the title of EURASCO Member Event. In 1972 the UFI Certified fair organizers formed EURASCO, which now has 25 members from 17 different countries.</p>

            <p>HKF Trade Fairs proceeds on its way with the addition of new shows to its event calendar. In 2015 our company broke a new ground by organizing TÜRKİYE’s very first Trade Fair for Road, Sea and Air Taxi Producers and Sub-industry TAXIWORLD TÜRKİYE. Another show that is going to take place as of 2016 is ISTANBUL MUSIC & DANCE. Istanbul will become the center of music and dance with this new show under the title of Music, Dance and Stage Equipment, Event Organization and Communication Technologies Fair. Another brand new trade fair that is going to take starting from 2016 is the Trade Fair for Drink Technologies, DRINKTEG ISTANBUL. Covering the machineries, equipment, process aids and all kinds of services in each stage of the sector, DRINKTEG ISTANBUL is going to bring the exhibitors together with the visitors under the same roof.</p>

            <p> In all the trade shows we organize we are truly committed to meet all the aspirations of both domestic and overseas participants by bringing them together with visitors of the highest caliber, by presenting the industry’s most recent developments and technologies and by organizing related forums and seminars. With an experienced team of employees, HKF Trade Fairs aims to establish a respectable position in the global exhibition industry. Having been accepted as a full member of UFI and having received the prestigious UFI Approved Certificate for our fairs, our primary aim is realized. Our aim now is to continue our services in the exhibition industry with our internationally recognized standards of quality.</p>


          </Col>
          <Col md={3}>
            <Submenu />
            <Bulten />
          
          </Col>
        </Row>

      </Container>
    </>
  )
}

export default Hakkimizda