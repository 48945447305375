import animalia from '../img/animalia.svg';
import busworld from '../img/busworld.svg';
import foteg from '../img/foteg.svg';
import taxiworld from '../img/taxiworld.svg';
import viv from '../img/viv.svg';
import Container from 'react-bootstrap/Container';


import React from 'react'

 const logos = () => {
  return (
    <div className='logocontainerbg mt-1'>


<Container>
    <h2>Our fairs<br/><i className="fa-sharp fa-solid fa-caret-down"></i></h2>
    
    <div className='line'></div>

<div className='logocontainer mb-3'>
<div className="logobox"> <a href="https://www.busworldturkey.org/en"><img src={busworld} alt="HKF"/></a></div>
<div className="logobox"><a href="http://www.fotegistanbul.com/"><img src={foteg} alt="HKF" /></a></div>
<div className="logobox"><a href="http://www.vivturkey.com/"><img src={viv} alt="HKF" /></a></div>
<div className="logobox"><img src={animalia} alt="HKF" /></div>
<div className="logobox"><img src={taxiworld} alt="HKF" /></div>
    </div>
   
    </Container>




    </div>
  )
}
export default logos;