import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import istanbul from "../img/istanbul.jpg";
import bekir from "../img/bekir_cakici.jpg";
import ufi from "../img/ufi.jpg";
import ufi2 from "../img/ufi2.jpg";
import ufi3 from "../img/ufi3.jpg";
import eurasco from "../img/eurasco.jpg";
import React from 'react'
import { Link } from "react-router-dom";



const Haberler = () => {
  return (

  
      <Container className='mt-3'>
        <h2 className='haber'>News from HKF<br /><i class="fa-sharp fa-solid fa-caret-down"></i></h2>
        <div className='line'></div>

        <Row className='mt-3'>

          <Col sm className='p-3'>
            <h3 className='newsh3'>HKF Trade Fairs was the Silver Sponsor of UFI Open Seminar in Europe</h3>
            <div className='line mt-3'></div>
            <img
              className="img-fluid mt-3"
              src={istanbul}
              alt="istanbul" />
            <br />

            <p className='mt-3'>The Global Association of the Exhibition Industry UFI chose Istanbul to hold the Open Seminar in 
            Istanbul from 15th to 17th of 2015. The theme of the seminar which was held in Hilton Istanbul Bosporus Hotel was 
            ‘Competing for the marketing budget – The future position of exhibitions in the marketing mix’.
            HKF Trade Fairs was the Silver Sponsor of UFI’s Open Seminar in Europe where 144 people from 34 countries attended.
            </p>


          
            <Link to="/Ufisponsor" type="button" class="btn btn-warning">Read more</Link>

          </Col>



          <Col sm className='p-3'><h3 className='newsh3'>For The First Time In Exhibition History Proudly Hosted the 75th UFI Congress!</h3>
            <div className='line mt-3'></div>

            <img
              className="img-fluid mt-3"
              src={ufi3}
              alt="ufi" />
            <br />
            <p className='mt-3'>

            The official opening of the UFI 75th Congress, hosted by HKF Trade Fairs, was held on Thursday 13 November while the congress ran from the 12 to the 15 November. Thus a UFI Congress was held in Istanbul Turkey for the first time in the history of the Turkish Exhibition.

UFI headquarters are in Paris and it was formed in 1925 by the world’s foremost 20 exhibition organizers. Today there are 532 members from more than 80 different

            </p>

           

            <Link to="/ufi" type="button" class="btn btn-warning">Read more</Link>

          </Col>



          <Col sm className='p-3'><h3 className='newsh3'>HKF Trade Fairs becomes a member of EURASCO</h3>
            <div className='line mt-3'></div>

            <img
              className="img-fluid mt-3"
              src={eurasco}
              alt="ufi" />
            <br />
            <p className='mt-3'>In 1972, title holders of UFI certified trade fairs formed the European Federation of Agricultural Trade Fair Organisers, or EURASCO, which now has 34 members from 25 different countries. EURASCO provides its members with advice and consultancy services and has an annual meeting for organisers of agricultural trade fairs.

Thus, with the principal of organising world standard quality international trade fairs. HKF Trade Fairs has the honour to be the first and only EURASCO member accepted from Turkey.</p>
          </Col>




        </Row>

        <div className='line'></div>

        <Row>

          <Col sm className='p-3'><h3 className='newsh3'>Mr. Bekir Çakıcı has been Vice-Chair of the UFI European Chapter from 2014 until 2020</h3>

            <div className='line mt-3'></div>
            <img
              className="img-fluid mt-3"
              src={ufi}
              alt="ufi" />
            <br />

            <p className='mt-3'>HKF Fuarcılık The Chairman of Hkf Trade Fairs, Mr. Bekir Çakıcı has been elected as Vice-Chair of the UFI European Chapter for the second time in 2018, a position he held until 2020.</p>

          </Col>



          <Col sm className='p-3'>

            <h3 className='newsh3'>Mr. Bekir Çakıcı has been elected as Vice-Chair of the UFI European Chapter for the second time</h3>

            <div className='line mt-3'></div>
            <img
              className="img-fluid mt-3"
              src={bekir}
              alt="bekir_cakici" />
            <br />

            <p className='mt-3'>The Chairman of Hkf Trade Fairs ,Mr. Bekir Çakıcı has been elected as Vice-Chair of the UFI European Chapter for the second time in 2018.</p>

          </Col>



          <Col sm className='p-3'><h3 className='newsh3'>General Manager of HKF Trade Fairs Mr. Bekir Çakıcı has been elected as a member of UFI Board of Directors</h3>
            <div className='line mt-3'></div>

            <img
              className="img-fluid mt-3"
              src={ufi2}
              alt="ufi" />
            <br />
            <p className='mt-3'>General Manager of HKF Trade Fairs Mr. Bekir Çakıcı has been elected as a member of UFI Board of Directors for a three year mandate. This is the fourth time that Mr. Çakıcı has been elected for this position following the elections held in 2006, 2008 and 2011. He has held this position until 2020.</p>

          </Col>




        </Row>


      </Container>
    

      

  )
}

export default Haberler