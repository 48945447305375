import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Submenu from "./Submenu"
import Bulten from "./Bulten"


function UfiSponsor() {
  return (
    <>

<div className="subpage3">
    <Container>
    <h3>HKF Trade Fairs was the Silver Sponsor of UFI Open Seminar in Europe</h3>
    </Container>

    </div>
    <Container className="mt-5">
      
    <Row>
        <Col md={9}>



          <h4>UFI chose Istanbul to hold the Open Seminar in Istanbul from 15th to 17th of 2015</h4>
          <div class="line2 mt-2 mb-3"></div>
       <p>

       The Global Association of the Exhibition Industry UFI chose Istanbul to hold the Open Seminar in Istanbul from 15th to 17th of 2015. The theme of the seminar which was held in Hilton Istanbul Bosporus Hotel was ‘Competing for the marketing budget – The future position of exhibitions in the marketing mix’.</p>

       <p>HKF Trade Fairs was the Silver Sponsor of UFI’s Open Seminar in Europe where 144 people from 34 countries attended. After the seminars participants had the opportunity to enjoy the city of Istanbul as part of their program thanks to the organization of HKF Trade Fairs. On the second day of the seminar participants went on a dinner cruise in Bosporus. Participants were mesmerized by the beauty of the city and the well-known Hagia Sophia that has a history of thousands of years.</p>

<p>‘This seminar in Istanbul was an indicator of the position of Turkey in the global exhibition industry. Turkey, particularly Istanbul, proofed that it is a desired market for exhibition industry. Trade shows in Europe that have grown enough started to move towards Eastern markets for the purpose of growing further. Acting as a bridge between East and West, Istanbul is now a focus city for the investors due to its location and the facilities available.UFI lends credence to this fact by organizing the Open Summer Seminar in Istanbul. HKF Trade Fairs family is proud to be the Silver Sponsor of such an event’ said Bekir Çakıcı, the General Manager of HKF Trade Fairs and Vice Chairman of the European Chapter of UFI regarding the seminar in Istanbul.</p>




        

         
        </Col>
        <Col md={3}>
         <Submenu/>
         <Bulten/>
        </Col>
      </Row>

    </Container>
    
    
    </>
  )
}

export default UfiSponsor