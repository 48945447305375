import React from 'react'
import Carousel from './Carousel';
import Logos from './Logos';
import Haberler from './Haberler'



function Home() {
  return (
    <div>

<Carousel/>
       <Logos/>
       <Haberler/>

    </div>
  )
}

export default Home