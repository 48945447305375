import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Submenu from "./Submenu"
import Bulten from "./Bulten"
import animalia from '../img/animalia.svg';
import busworld from '../img/busworld.svg';
import foteg from '../img/foteg.svg';
import taxiworld from '../img/taxiworld.svg';
import viv from '../img/viv.svg';

const Fuarlar = () => {
  return (
    <>
    
    
    <div className="subpage2">
    <Container>
    <h3>Prime quality international trade fairs in Türkiye</h3>
    </Container>

    </div>
    <Container className="mt-5">

    <Row>
        <Col md={9}>
         
        <Container>

        <h4>Upcoming Events</h4>
          <div className='line2 mt-2 mb-3'></div>


  

          <Row>
        <Col sm={3}><div className="logobox"><img src={busworld} alt="HKF" /></div></Col>
        <Col sm={9}>

        <p>
  
    

        <span className='tkv'>BUSWORLD TÜRKİYE 2024</span><br/>
        10th International Trade Fair For Bus Industry and Accessories<br/>
  29-31 MAY 2024<br/>
  ISTANBUL EXPO CENTER<br/>
  www.busworldturkey.com
  </p>



        </Col>
      </Row>








    <Row>
<Col sm={12}>

<div className="line2 mt-1 mb-4"></div>

</Col>

    </Row>

      <Row>
        <Col sm={3}><div className="logobox"><img src={foteg} alt="HKF" /></div></Col>
        <Col sm={9}>
        <p>
  
    

        <span className='tkv'>FOTEG ISTANBUL 2025</span><br/>
        17th International Trade Fair For Food &  Food Processing Technologies<br/>
        24-26 APRIL 2025<br/>
  ISTANBUL EXPO CENTER HALL 5-6<br/>
  www.fotegistanbul.com
  </p>

</Col>
      </Row>



      <Row>
<Col sm={12}>

<div className="line2 mt-1 mb-4"></div>

</Col>

    </Row>

    <Row>
        <Col sm={3}><div className="logobox"><img src={viv} alt="HKF" /></div></Col>
        <Col sm={9}>

        <p>
  
    

  <span className='tkv'>VIV  SELECT TÜRKİYE 2025</span><br/>
  11th International Trade Fair For Poultry Technologies<br/>
  24-26 APRIL 2025<br/>
  ISTANBUL EXPO CENTER HALL 2-4-8<br/>
  www.vivturkey.com
  </p>

</Col>
      </Row>

     


      
      <Row>
<Col sm={12}>

<div className="line2 mt-4 mb-4"></div>

</Col>

    </Row>


    <Row>
        <Col sm={3}><div className="logobox"><img src={animalia} alt="HKF" /></div></Col>
        <Col sm={9}>

        <p>
  
    

        <span className='tkv'>ANIMALIA İSTANBUL</span><br/>
        International Trade Fair For Cattle Breeding Technologies & Animal Products<br/>

  </p>



        </Col>
      </Row>
      <Row>
<Col sm={12}>

<div className="line2 mt-4 mb-4"></div>

</Col>

    </Row>

    <Row>
        <Col sm={3}><div className="logobox"><img src={taxiworld} alt="HKF" /></div></Col>
        <Col sm={9}>

        <p>
  
    

        <span className='tkv'>TAXIWORLD TÜRKİYE</span><br/>
        Trade Fair for Road, Sea and Air Taxi Producers and Subindustry<br/>

       
  </p>



        </Col>
      </Row>

<br/>


    </Container>
         
        </Col>
        <Col md={3}>
         <Submenu/>
         <Bulten/>
        </Col>
      </Row>

    </Container>
   
    
    </>
  )
}

export default Fuarlar