import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Home from "./Home"
import Fuarlar from "./Fuarlar"
import Iletisim from "./Iletisim"
import Hakkimizda from "./Hakkimizda"
import Ik from "./ik"
import Ufi from "./Ufi"
import UfiSponsor from './Ufi-sponsor';





const Menu = () => {
  return (
    <Router>
      <Container className="mb-1 mbg sticky-sm-top" fluid>

        <Navbar collapseOnSelect expand="xl" bg="transparent" variant="dark" style={{ fontWeight: "700", letterSpacing: "2px" }} >
          <Container>
            <Navbar.Brand href="#home"></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto ">
                <Nav.Link as={Link} to={"/"} className="me-3  p-1" exact>HOMEPAGE</Nav.Link>
                <Nav.Link as={Link} to={"/aboutus"} className="me-3  p-1">ABOUT US</Nav.Link>
                <Nav.Link as={Link} to={"/fairs"} className="me-3  p-1">OUR FAIRS</Nav.Link>
                <Nav.Link as={Link} to={"/ufi"} className="me-3  p-1">75. UFI CONGRESS</Nav.Link>
                <Nav.Link as={Link} to={"/ik"} className="me-3  p-1">HUMAN RESOURCE</Nav.Link>
                <Nav.Link as={Link} to={"/contact"} className="me-3  p-1">CONTACT US</Nav.Link>


              </Nav>
              <Nav className='me-3  p-1'>
                <Nav.Link href="https://www.hkffuarcilik.com/">Turkish</Nav.Link>

              </Nav>
            </Navbar.Collapse>
          </Container>


        </Navbar>


      </Container>

      <Switch>
          <Route path="/aboutus">
            <Hakkimizda />
          </Route>
          <Route path="/fairs">
            <Fuarlar />
          </Route>
          <Route path="/ufi">
            <Ufi/>
          </Route>
          <Route path="/ik">
            <Ik/>
          </Route>
          <Route path="/contact">
            <Iletisim />
          </Route>
          
        <Route path="/Ufisponsor" >
          <UfiSponsor/>
        </Route>

          <Route path="/">
            <Home />
          </Route>
        </Switch>

    </Router>
  )
}




export default Menu;