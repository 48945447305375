import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Submenu from "./Submenu"
import Bulten from "./Bulten"
import U1 from '../img/ufi_genel.jpg';




function Ufi() {
  return (
    <>

<div className="subpage3">
    <Container>
    <h3>For The First Time In The Turkish Exhibition History Proudly Hosted the 75th UFI Congress!</h3>
    </Container>

    </div>
    <Container className="mt-5">


 
      
    <Row>
        <Col md={9}>


        <h4>75th UFI CONGRESS</h4>
          <div className='line2 mt-2 mb-3'></div>


      
          <img className='img-fluid' src={U1} alt="UFI" />
         
       <p className="mt-3">The official opening of the UFI 75th Congress, hosted by HKF Trade Fairs, was held on Thursday 13 November while the congress ran from the 12 to the 15 November. Thus a UFI Congress was held in Istanbul TÜRKİYE for the first time in the history of the Turkish Exhibition. </p>
<p>

UFI headquarters are in Paris and it was formed in 1925 by the world’s foremost 20 exhibition organizers. Today there are 532 members from more than 80 different countries in six different continents.</p>

<p>UFI maintains its position as the most respected organisation of the world exhibition industry with TÜRKİYE boasting 19 members alone. One of the most important functions of the association is the vetting and issuing of ‘UFI Approved Event’ certificates for members’ exhibitions, making it the industry’s most sought after certification.</p>

<p>The guests were welcomed by their hosts, HKF Trade Fairs. The Congress received the full support of the President of The Union of Chambers and Commodity Exchanges of TÜRKİYE, Mr. Rıfat Hisarcıklıoğlu; the Mayor of Istanbul, Mr. Kadir Topbas and the President of the Istanbul Chamber of Commerce, Mr. Murat Yalçıntas.</p>

<p>The 75th UFI Congress was attended by 355 delegates of CEO status coming from 58 different countries of whom 60 came with their partners. The 75th UFI Congress’ opening address was delivered by UFI president, Mr. John Shaw. General Manager of HKF Mr. Bekir Çakıcı then made a presentation titled: “TÜRKİYE, a market rich in business opportunities” demonstrating the potential for the exhibition industry in the Turkish market and he also gave a brief about the history of fairs.


        </p>
<p>

To celebrate the first Congress to be held in TÜRKİYE, the 75th UFI Congress’ gala dinner was held for the delegates at the Sait Halim Pasha Mansion with the hospitality of The Union of Chambers and Commodity Exchanges of TÜRKİYE.


</p>



<p>

The delegates’ partners were treated to a boat tour of the Bosphorus and a museum tour on the 13th and 14th of November while all the CEO’s were able to join their partners for a tour of Hagia Sophia and the Topkapı Palace on the 15th.
</p>
         

<p>

While always choosing the world’s great cities for their congress venues, the choice of Istanbul for the 75th UFI Congress not only enabled the city to take its place amongst the World’s Foremost Exhibition Venues, but it was also a golden opportunity to show the city’s exhibition potential off to the CEO’s of the world’s most celebrated exhibition grounds and organizing companies.


</p>


        </Col>
        <Col md={3}>
         <Submenu/>
         <Bulten/>
        </Col>
      </Row>

    </Container>
    
    
    </>
  )
}

export default Ufi