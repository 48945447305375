import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Menu from "./component/Navbar"
import Header from "./component/Header"
import Footer from "./component/Hfooter"

import {
  BrowserRouter as Router} from "react-router-dom";


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Router>
<Header/>
<Menu/>
<App/>
<Footer/>


</Router>
  </React.StrictMode>

);

